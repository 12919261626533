// Configuration for the font-face of the theme, defaults to the system font so left as blank
@font-face{
  font-family: "siemensRegular";
  src: url("../../../../fonts/siemenssans-roman-webfont.eot");
  src: url("../../../../fonts/siemenssans-roman-webfont.eot?#iefix") format("embedded-opentype"),
  url("../../../../fonts/siemenssans-roman-webfont.woff") format("woff"),
  url("../../../../fonts/siemenssans-roman-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family: "siemensBold";
  src: url("../../../../fonts/siemenssans-bold-webfont.eot");
  src: url("../../../../fonts/siemenssans-bold-webfont.eot?#iefix") format("embedded-opentype"),
  url("../../../../fonts/siemenssans-bold-webfont.woff") format("woff"),
  url("../../../../fonts/siemenssans-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
$fontFamily: siemensRegular, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";