.site-header{
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: $siemens-deep-blue;
  /*box-shadow: 0 0 5px 3px $siemens-bold-blue,
  0 -5px 15px 5px $siemens-bold-blue,
  0 0 25px 5px $siemens-bold-blue;*/
  border-bottom:1px solid $siemens-bold-blue;
  padding: 0 20px;
}
.site-header--links{
  display:flex;
  align-items: center;
  .p-button-link,
  .p-button.p-button-link{
    color:$siemens-nav-link-color;
    font-family: siemensRegular, sans-serif;

    &:hover,
    &:focus,
    &:active{
      color: $siemens-nav-link-color-hover;
      text-decoration: none;
      outline:0;
      box-shadow: none;
    }
  }
  a{
    text-decoration:none;
  }
}
/*
.site-header > ul{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  height: 100%;
}
.site-header > ul > li{
  padding: 0 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.site-header > ul > li > a{
  padding: 1em;
  color: $siemens-nav-link-color;
  text-decoration: none;
}
.site-header > ul > li > a:hover,
.site-header > ul > li > a:active,
.site-header > ul > li > a:focus{
  color: $siemens-nav-link-color-hover;
  text-decoration: underline;
}
*/

.site-header #logo{
  height: 20px;
  width: 130px;
}