/*
1. general fader
2. Slider handle
3. windows scaling 125% - 150% - 1920x1080 laptops
 */

.fader{
  height:100%;
  width:100px;
  margin:0 0.5rem;
  box-sizing: border-box;
  .slider{
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: center;
    background:url(./images/fader-lines.svg);
    background-repeat: repeat-y;
    background-position-y: top;
  }
  .slider--label{
    margin-bottom: 24px;
  }
  .slider--label,
  .slider--label p{
    color:var(--surface-900);
  }
  .slider--label p{
    margin:0;
  }
  .slider--btnLabel{
    background:$siemens-bg-blue;
    width:100%;
    text-align:center;
    p{
      font-size:0.875rem;
      margin:0.25rem 0;
      color:$siemens-bold-blue;
    }
  }
  .p-slider-vertical{
    height:100% !important;
  }
  .p-slider.p-slider-vertical {
    width: 0.8rem;
    border-radius:0.2625rem;
    border: 4px solid $siemens-deep-blue;
  }
  .p-slider.p-slider-vertical .p-slider-range{
    border-radius:0.2625rem;
  }
  .p-slider{
    background:$siemens-vertical-fader-track-color;
  }
  .slider--value{
    color:$siemens-bold-blue;
    p{
      color:$siemens-bold-blue;
      margin:2px 0;
    }
  }
  .faderSelect{
    height: 196px;
    @media screen and (min-width: $breakpoint-large){
      height: 230px;
    }
    @media screen and (min-width: $breakpoint-xlarge){
      height: 230px;
    }
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    width: 100%;
  }
  .faderSelect--inner{
    height: 100%;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
  }
  .faderSelect--value{
    background:$siemens-bg-blue;

    width:100%;
   /*width:max-content;*/
    margin:4px 0;
    /*line-height:1.1;*/
    min-height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: $breakpoint-large){
      margin:12px 0 12px 0;
    }
    p{
      color:$siemens-bold-blue;
      margin:0;
      line-height:1.1;
      padding: 4px;
    }
  }

}

.soundcontrols .p-slider{
  background:$siemens-deep-blue;
}
.p-slider .p-slider-handle {
  /*height: 1.143rem;
  width: 1.143rem;*/
  /*height: 1.5rem;
  width: 1.5rem;*/
  height: 2.5rem;
  width: 2.5rem;
  background: $siemens-petrol;
  border: 2px solid $siemens-bold-blue;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover,
.p-slider .p-slider-handle:focus{
  background: $siemens-petrol;
  border-color: $siemens-bold-blue;
  /*-webkit-box-shadow:0 0 16px 9px rgba($siemens-bold-blue,1);
  -moz-box-shadow: 0 0 16px 9px rgba($siemens-bold-blue,1);
  box-shadow: 0 0 16px 9px rgba($siemens-bold-blue,1);*/
  box-shadow:none;
  outline: none;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -1rem;
  height: 2rem;
  width: 2rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -1.25rem;
}
.p-slider.p-slider-horizontal {
  /*height: 0.286rem;*/
  border-radius:0.143rem;
  .p-slider-range{
    border-radius:0.143rem;
  }
}



/*
3. windows scaling 125% - 150% - 1920x1080 laptops
 */
@media screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width:$breakpoint-large) and (max-width: $breakpoint-superlarge +1){

  .fader .p-buttonset{
    width:64px;
    margin:0 auto;
    .p-button{
      width:32px;
      height:32px;
      font-size:0.875rem;
      padding:0.5rem;
    }
  }
  .fader .faderSelect--value{
    background:transparent;
    margin:0;
    min-height: 10px;
  }
  .fader .faderSelect {
    height: 115px;
  }
  .fader .p-slider .p-slider-handle {
    height: 2rem;
    width: 2rem;
  }
  .fader .p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -1rem;
  }
}