/*
1. General styling desktop / mobile
2. fix for windows scaling 125%-150%
 */
.nav--info >div{
  gap:0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;
  height: 100%;
  width:100%;
  /*overflow-x:scroll;
  --scrollbar-foreground: var(--siemens-petrol);
  --scrollbar-background: var(--bg-blue);
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
  @media screen and (min-width:$breakpoint-medium){
    overflow-x:hidden;
  }*/
  @media screen and (min-width:$breakpoint-large){
    gap:1rem;
  }
}
.nav--info{

  /*display: flex;
  flex-direction: row;
  justify-content: space-between;*/
  padding: 0;
  margin: 0;
  /*list-style: none;*/
  height: 100%;
  width:100%;

  /*.p-button svg,
    button svg{
    display:none;
    @media screen and (min-width:$breakpoint-medium + 1){
      display:block;
      width:32px;
      height:32px;
      margin-bottom:5px;
    }
    @media screen and (min-width:$breakpoint-large){
      width:48px;
      height:48px;
    }
    @media screen and (min-width:$breakpoint-xlarge){
      width:64px;
      height:64px;
    }
  }*/
  .p-button span,
  button span{
    color:var(--gray-100);
    font-family:siemensRegular, sans-serif;
  }
  .p-button,
  button{
    padding:5px;
    display:flex;
    flex-direction:column;
    flex-wrap:nowrap;
    justify-content:center;
    align-items:center;
    /*width: 90px;*/
    border-radius: 0;
    background: $siemens-bg-blue;
    border:1px solid transparent;
    transition:all 0.5s ease-in-out;
    cursor:pointer;
    min-width:max-content;
    width:20%;
    &.highlighted-button,
    &:hover,
    &:active,
    &:focus{
      background: $siemens-bg-blue;
      border-top:1px solid transparent;
      border-left:1px solid transparent;
      border-right:1px solid transparent;
      border-bottom:1px solid $siemens-bold-blue;
      transition:all 0.5s ease-in-out;
      span{
        color:$siemens-bold-blue;
        transition:all 0.5s ease-in-out;
      }
      svg path{
        fill:$siemens-bold-blue !important;
        transition:all 0.5s ease-in-out;
      }
    }
    svg{
      display:none;
      /*@media screen and (min-width:$breakpoint-medium + 1) and (orientation:landscape){
        display:block;
        width:32px;
        height:32px;
        margin-bottom:5px;
      }*/
      /*
      @media screen and (min-width:$breakpoint-large) and (orientation:landscape){
        width:48px;
        height:48px;
      }
      @media screen and (min-width:$breakpoint-xlarge) and (orientation:landscape){
        width:64px;
        height:64px;
      }
      @media screen and (orientation:portrait) and (min-width: $breakpoint-medium) and (max-width: $breakpoint-large){
        display:block;
        width:48px;
        height:48px;
        margin-bottom:5px;
      }
      */

      @media screen and (min-width:$breakpoint-large + 1){
        display:block;
        margin-bottom:5px;
        width:48px;
        height:48px;
      }
      @media screen and (min-width:$breakpoint-xxlarge){
        display:block;
        margin-bottom:10px;
        width:64px;
        height:64px;
      }

    }
    span{
      font-size:0.75rem;
      width: auto;
      @media screen and (min-width:$breakpoint-xsmall) {
        width: max-content;
      }
      @media screen and (min-width:$breakpoint-large + 1) {
        width:auto;
      }
    }
    @media screen and (min-width:$breakpoint-large + 1) {
      border-radius: 20px;
      &.highlighted-button,
      &:hover,
      &:active,
      &:focus {
        border: 1px solid $siemens-bold-blue;
        transition: all 0.5s ease-in-out;
      }
    }

    @media screen and (min-width:$breakpoint-xlarge + 1) and (orientation:landscape){
      width: 100px;
      height: 120px;
      span{
        font-size:0.875rem;
      }
    }

    @media screen and (min-width:$breakpoint-xxlarge) and (orientation:landscape){
      width: 120px;
      height: 120px;
      span{
        font-size:1rem;
      }
    }
    /*
    @media screen and (orientation:portrait) and (min-width: 320px) and (max-width: $breakpoint-large + 1){
      width:20%;
      border-radius:0;
      align-self:stretch;
      border-right:1px solid $siemens-deep-blue;
      &:hover,
      &:active,
      &:focus{
        background: $siemens-bg-blue;
        border-bottom:1px solid $siemens-bold-blue;
        border-top:1px solid transparent;
        border-left:1px solid transparent;
        border-right:1px solid $siemens-deep-blue;
        transition:all 0.5s ease-in-out;
      }
      &:last-child{
        border-right:1px solid transparent;
      }
      span{
        font-size:0.75rem;
      }
    }
    @media screen and (min-width:$breakpoint-large + 1){
      border-radius: 20px;
      width:120px;
      &:hover,
      &:active,
      &:focus{
        background: $siemens-bg-blue;
        border:1px solid $siemens-bold-blue;
        transition:all 0.5s ease-in-out;
      }
    }
    @media screen and (orientation:portrait) and (min-width: $breakpoint-medium) and (max-width: $breakpoint-large){
      width:20%;
      max-width:120px;
      border-radius: 20px;
    }*/

  }


}
/*
2. fix for windows scaling 125%-150%
*/
@media screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width:$breakpoint-large) and (max-width: $breakpoint-superlarge +1){
  .nav--info .p-button, .nav--info button {
    width: 100px;
    height: 104px;
  }
  .nav--info .p-button svg, .nav--info button svg {
    display: block;
    margin-bottom: 5px;
    width: 32px;
    height: 32px;
  }
}