.p-scrollpanel.siemensScrollBar{
  height:calc(100vh - 190px);

  @media screen and (min-width:$breakpoint-large + 1) {
    height:calc(100vh - 305px);
  }
  .p-scrollpanel-bar{
    background:$siemens-petrol;
    opacity:1;
    &:hover{
      /*box-shadow: 0 0 8px 4px rgba($siemens-bold-blue,0.5);*/
      box-shadow: 0 0 0 2px $siemens-petrol;
    }
    &:active,
    &:focus{
      /*box-shadow: 0 0 8px 6px rgba($siemens-bold-blue,0.5);*/
      outline: none;
      box-shadow: 0 0 0 2px $siemens-petrol;
    }
  }
  .p-scrollpanel-bar-y{

  }
  /*.p-scrollpanel-content {
    padding: 0 32px 18px 0;
  }*/
  .p-scrollpanel-wrapper{
    padding-right:24px;
  }
}


@media screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width:$breakpoint-large) and (max-width: $breakpoint-superlarge +1){
  .p-scrollpanel.siemensScrollBar {
    height: calc(100vh - 230px);
  }
}