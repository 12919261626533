.p-dropdown {
  background: $siemens-bg-blue;
  border: 1px solid $siemens-petrol;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
  &:hover .p-dropdown-label,
  &:hover .p-dropdown-trigger{
    color:$siemens-bold-blue;
  }
  .p-dropdown-trigger{
    color:#fff;
  }
}
.p-dropdown-panel {
  background: $siemens-bg-blue;
  color: #FFF;
  border: 1px solid $siemens-petrol;
  border-radius: 10px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #fff;
  background:$siemens-petrol;
}
.p-dropdown:not(.p-disabled).p-focus{
  outline:0;
  box-shadow:none;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}