/*
1.Regular select buttons
2. group of 4 buttons - first 2 fader
3. windows scaling 125% - 150% - 1920x1080 laptops
 */

/* select button - p-selectbutton */
.p-selectbutton .p-button:not(.p-button-icon-only){
  font-size:1.25rem;
  font-family: siemensBold, sans-serif;
}
.p-selectbutton .p-button{
  /*background:rgba($siemens-petrol,0.5);*/
  color:$siemens-deep-blue;
  background:$siemens-petrol;
  padding: 0.625rem 0.99rem;
}
.p-selectbutton .p-button.p-highlight,
.p-selectbutton .p-button.p-highlight:hover{
  color:$siemens-deep-blue;
  background:$siemens-bold-blue;
}
.p-selectbutton .p-highlight,
.p-selectbutton .p-highlight:focus,
.p-selectbutton .p-highlight:active{
  /*box-shadow: inset 2px 2px 2px 2px rgba(255,255,255,0.2),
              inset -2px -1px 2px 2px rgba(0,0,0,0.2);*/
  /*text-shadow: 0px 0px 4px rgba(0, 230, 220, 1),
              2px 2px 6px rgba(0, 230, 220, 1),
              -2px -2px 6px rgba(0, 230, 220, 1);*/
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  border-color: transparent;
}
.p-buttonset .p-button{
  border-radius:10px;
}
/*
.p-buttonset .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-buttonset .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
*/
/*
2. group of 4 buttons - first 2 fader
---------------------------------------------------------------------------------------------------
*/
.fader .p-selectbutton{
  margin: 0 auto;
  width: 96px;
  .p-button{
    width:48px;
    height:48px;
  }
}
//.fader:nth-child(1) .p-selectbutton,
//.fader:nth-child(2) .p-selectbutton{
//  .p-button:nth-child(1){
//    border-bottom-left-radius: 0;
//  }
//  .p-button:nth-child(2){
//    border-top-right-radius: 10px;
//  }
//  .p-button:nth-child(3){
//    border-bottom-left-radius: 10px;
//  }
//  .p-button:nth-child(4){
//    border-top-right-radius: 0;
//    border-bottom-right-radius: 10px;
//  }
//}

