.p-button{
  $borderRadius:0;
  border-radius: $borderRadius;
  font-family: siemensBold, sans-serif;
  padding: 0.625rem 1.5rem;

}
.p-scrollpanel-content{
  .p-button{
    background:$siemens-bold-blue;
    border:1px solid $siemens-bold-blue;
    &:hover,
    &:active,
    &:focus{
      color:$siemens-deep-blue;
      background:$siemens-btn-primary-hover;
      border:1px solid $siemens-btn-primary-hover;
    }
  }
}
.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:enabled:active,
.p-button.p-button-outlined:enabled:focus{
  background: $siemens-petrol;
  color: $siemens-deep-blue;
  border: 1px solid $siemens-petrol;
}
.p-button:enabled:active {
  background: $siemens-deep-blue;
  color: $siemens-petrol;
  border-color: $siemens-deep-blue;
}