.share{
  grid-row-start: 2;
  grid-row-end: 3;
  @media screen and (min-width:$breakpoint-large + 1) {
    grid-column: 1 / span 2;
    grid-row-end: 4;
  }
  .p-card{
    background:transparent;
    /*max-width: 600px;*/
    /*min-width: 16em;*/
    min-width: 200px;
    max-width: 28em;
    max-width: 70ch;
    margin: 0 auto;
  }
  .shareButtons{
    a{
      display:inline-block;
      margin:0.5rem;
    }
  }
}
/* ALL TEXT PAGES ARE IN DE SHARE DIV.. */
.share{
  overflow-y: scroll;
  --scrollbar-foreground: var(--siemens-petrol);
  --scrollbar-background: var(--bg-blue);
  /* Foreground, Background */
  /*scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);*/
  scrollbar-color: var(--scrollbar-foreground);
  scrollbar-width: thin;
}
.share::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}
.share::-webkit-scrollbar-thumb { /* Foreground */
  background: var(--scrollbar-foreground);
  border-radius: 5px;
}
.share::-webkit-scrollbar-track { /* Background */
  background: var(--scrollbar-background);
  border-radius: 5px;
}

/* NO Siemens scrollbar scrollpanel */
.share{
  .p-scrollpanel.siemensScrollBar{
    height:auto;
  }
}