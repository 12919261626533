/*
1. General styling desktop / mobile
2. fix for windows scaling 125%-150%
 */

.soundcrontrols--wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
  position:relative;
  /*.soundcontrols--label{
    margin: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 100;
    top: -20px;
    display:none;
    @media screen and (min-width:$breakpoint-large + 1) {
      display:block;
      top: -24px;
    }
  }
  .soundcontrols--label span{
    font-size: 0.875rem;
    background: $siemens-deep-blue;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 20px;
    margin: 0 auto;

  }*/
  .soundcontrols--label{
    display:none;
  }
}

.p-card.soundcontrols{
  border-radius:20px;
  background:$siemens-bg-blue;
  height:100%;
  .mr-3 {
    margin-right: 0.2rem !important;
    @media screen and (min-width:$breakpoint-large + 1) {
      margin-right: 1rem !important;
    }
  }
  .p-card-body {
    padding: 0.5rem 0;
    @media screen and (min-width:$breakpoint-medium) {
      padding: 0.5rem;
    }
    @media screen and (min-width:$breakpoint-large + 1) {
      padding: 0.5rem 1rem;
    }
  }
  svg{
    width:24px;
    height:24px;
  }
  svg#soundOn,
  svg#speaker-icon{
      margin-bottom: 24px;
  }

  .p-slider.p-component{
      margin-bottom: 24px;
  }
  .p-togglebutton.p-button,
  .p-button.p-button-icon-only,
  .p-button{
    border-radius:10px;
    background:$siemens-petrol;
    color:$siemens-deep-blue;
    border-color: transparent;
    border:1px solid transparent;
    border-radius:10px;
    /*box-shadow: inset 2px 2px 2px 2px rgba(255,255,255,0.2),
                inset -2px -1px 2px 2px rgba(0,0,0,0.2);*/
    &.p-highlight.p-focus{
      background:$siemens-bold-blue !important;
    }
  }

  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: $siemens-bold-blue;
    border-color: transparent;
    border:1px solid transparent;
    color: $siemens-deep-blue;
    /*filter: url(#sofGlow);*/
  }
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: $siemens-deep-blue;
  }
  .p-togglebutton.p-button.p-highlight .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: $siemens-deep-blue;
  }
  p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: $siemens-deep-blue;
    box-shadow:none;
    outline:0;
  }
  .p-togglebutton.p-button:not(.p-disabled).p-focus {
    color: $siemens-deep-blue;
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: transparent;
  }
  .p-buttonset .p-button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    /*box-shadow: inset 2px 2px 2px 2px rgba(255,255,255,0.2),
                inset 0 0 2px 2px rgba(0,0,0,0.2);*/
  }
  .p-buttonset .p-button:hover{
    background:$siemens-bold-blue;
    transition:all 0.5s ease-in-out;
  }
  .p-buttonset .p-button:not(:last-child) {
    /*border-right: 0 none;*/
    border-right:2px solid $siemens-bg-blue;
    &:hover,
    &:active,
    &:focus{
      border-right:2px solid $siemens-bg-blue;
    }
  }
  .p-buttonset .p-button:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    /*box-shadow: inset 2px 2px 2px 2px rgba(255,255,255,0.2),
                inset 0 0 2px 2px rgba(0,0,0,0.2);*/
  }
  .p-buttonset{
    display:flex;
  }
  .p-button,
  .btn-soundcontrol{
    padding:0;
    width:44px;
    height:44px;
    display:flex;
    justify-content:center;
    align-items:center;
    @media screen and (min-width:$breakpoint-xsmall){
      width:48px;
      height:48px;
    }
  }
  .soundcontrol--label {
    display:block;
    width:100%;
    text-align:center;
    margin-top:8px;
    font-size:0.875rem;
  }
  /* HOVERS WITH SVG */
  .btn-soundcontrol svg{
    width: 24px;
    height: 24px;
    overflow: visible;
    transition:opacity 0.5s ease-in-out;
  }
  .btn-soundcontrol svg#reset{
    width: 20px;
    height: 20px;
  }
  .btn-soundcontrol:hover,
  .p-button:hover{
    background:$siemens-bold-blue;
    border-color: transparent;
    border:1px solid transparent;
  }
  .btn-soundcontrol:hover svg{
    /*filter: drop-shadow(0 0 6px var(--bold-blue));*/
    /*filter: url(../img/filter.svg#sofGlow);*/
    /*filter: url(#sofGlow);
    transition:opacity 0.5s ease-in-out;*/
  }
  .btn-soundcontrol--reset svg #reset-hover{
    display: none;
    /*opacity:0;
    transition:opacity 0.5s ease-in-out;*/
  }
  .btn-soundcontrol--reset:hover svg #reset-hover{
    display: none;
    /*display: block;*/
    /*opacity:0;
    transition:opacity 0.5s ease-in-out;*/
  }
  /*
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: $siemens-bold-blue;
    border-color:transparent;
    border-left:1px solid transparent;
    border-top:1px solid transparent;
    border-bottom:1px solid transparent;
    border-right:2px solid $siemens-bg-blue;
    color: $siemens-deep-blue;
  }
  */
}

/* TOGGLE BUTTONS COMPARISON Master Sound Controls
 Hide regular icon and use svg
 */

.p-card.soundcontrols{

  .p-selectbutton .p-button i.pi-refresh:before{
    content: url(images/arrow2.svg);
    display: block;
    width: 24px;
    height: 24px;
  }
  .p-selectbutton .p-button i.pi-star:before{
    content: url(images/volumMixer2.svg);
    display: block;
    width: 24px;
    height: 24px;
  }
}
/* TOGGLE BUTTONS COMPARISON Highlight
 */
.p-card.soundcontrols{
  .p-selectbutton .p-button.p-highlight{
    background:$siemens-bold-blue;
  }
}
/* ANIMATED SPEAKER ICON */
#speaker-icon{
  width: 24px;
  height: 24px;
  #small,
  #large{
    display:none;
  }
}

#speaker-icon.animated #small{
  display:block;
  animation: smallfade 1s infinite;

}
#speaker-icon.animated #large{
  display:block;
  animation: largefade 2s infinite;
}
@keyframes smallfade {
  0%    { opacity: 1; }
  50%   { opacity: 0; }
  100%  { opacity: 1; }
}
@keyframes largefade {
  0%    { opacity: 1; }
  50%   { opacity: 0; }
  100%  { opacity: 1; }
}

/* MOBILE soundcontrols */
@media screen and (max-width: 1024px)
{
  .p-card.soundcontrols .soundcontrol--label{
    display: none;
  }
  .p-card.soundcontrols .p-card-content {
    padding: 4px 0;
  }
  .p-card.soundcontrols .p-slider.p-component{
    margin-bottom:0;
  }
  .p-card.soundcontrols svg#speaker-icon {
    margin-bottom: 0;
  }

}

/*
2. fix for windows scaling 125%-150%
*/
@media screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width:$breakpoint-large) and (max-width: $breakpoint-superlarge +1){
  .p-card.soundcontrols{
    .p-button{
      width:32px;
      height:32px;
    }
    .p-selectbutton .p-button i.pi-refresh:before{
      width: 16px;
      height: 16px;
    }
    .p-selectbutton .p-button i.pi-star:before{
      width: 16px;
      height: 16px;
    }
    .btn-soundcontrol svg#reset {
      width: 16px;
      height: 16px;
    }
  }
}