.site-footer{
  background: $siemens-petrol;
  color:$siemens-deep-blue;
}
.site-footer > div{
  height: 100%;
  min-height:40px;
}
.site-footer ul{
  color:$siemens-deep-blue;
  margin: 0;
  padding: 0 20px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;

}
.site-footer a,
.site-footer .p-button,
.site-footer button{
  color:$siemens-deep-blue;
  background:transparent;
  border-color:transparent;
  text-decoration:none;
  &:hover,
  &:active,
  &:focus{
    color:$siemens-bold-blue;
    text-decoration:underline;
    background:transparent;
    border-color:transparent;
  }
}