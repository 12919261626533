$primaryColor: #81C784 !default;
$primaryLightColor: scale-color($primaryColor, $lightness: 30%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #212529 !default;

$highlightBg: rgba(129, 199, 132, .16) !default;
$highlightTextColor: rgba(255,255,255,.87) !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import '../_variables';
