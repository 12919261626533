.v-tour--active{
   /* BACKDROP */
   &:before{
      z-index:100;
      position:absolute;
      content:'';
      top:0;
      right:0;
      left:0;
      bottom:0;
      background:rgba($siemens-deep-blue,0.5);
      /*backdrop-filter: blur(5px);*/
   }
   /* POPUP */
   .v-step{
      z-index:200;
      background:$siemens-title-color;
      color:$siemens-deep-blue;
      padding:1.5rem;
      max-width: 90vw;
      h1,h2,h3,h4,h5,h6,
      p,ol,ul{
         color:$siemens-deep-blue;
      }
      a{
         color:$siemens-petrol;
         &:hover,
         &:active,
         &:focus{
            color:$siemens-bold-blue;
         }
      }
      h1,h2,h3,h4,h5,h6{
         margin:0.5em 0;
      }
      .v-step__header{
         background:$siemens-title-color;
         color:$siemens-deep-blue;
         font-family: siemensBold, sans-serif;
         font-size:1.25rem;
         font-weight:bold;

      }
      .v-step__content{
         background:$siemens-title-color;
         color:$siemens-title-color;
         text-align:left;
      }
      .v-step__arrow--dark:before {
         background: $siemens-title-color;
      }

      .v-step__arrow, .v-step__arrow:before {
         position: absolute;
         width: 20px;
         height: 20px;
         background: inherit;
      }
      .v-step__arrow:before{
         background:#FFF;
      }

      .v-step__button{
         /*background:$siemens-petrol;*/
         background:$siemens-color-button-primary-default;
         color:$siemens-deep-blue;
         font-size:1rem;
         padding: 0.625rem 0.99rem;
         font-family: siemensBold, sans-serif;
         height: auto;
         line-height: 1;
         &:hover,
         &:focus,
         &:active{
            background:$siemens-color-button-primary-default-hover;
         }
         @media screen and (min-width:$breakpoint-large + 1) {
            padding: 0.625rem 1.5rem;
         }
      }
      @media screen and (min-width:$breakpoint-medium) and (max-width: $breakpoint-large) {
         max-width:60vw;
      }
      @media screen and (min-width:$breakpoint-large + 1) {
         max-width:600px;
      }
     /* SKIP/STOP TOUR BUTTON */
     .v-step__button-skip,
     .v-step__button-stop{
       border:1px solid $siemens-deep-blue;
       background:transparent;
       color:$siemens-deep-blue;
       &:hover,
       &:active,
       &:focus{
         border:1px solid $siemens-deep-blue;
         background:$siemens-deep-blue;
         color:$siemens-title-color;
       }
     }
   }
   /* TOUR ITEM */
   .v-tour__target--highlighted{
      z-index:100;
      /*box-shadow: 0 0 16px 4px $siemens-bold-blue;*/
     box-shadow:none;
   }
   /* ARROW PLACEMENT OF POPUP */
   .v-step[data-popper-placement^=right]>.v-step__arrow {
      left: -20px;
   }
   .v-step[data-popper-placement^=bottom]>.v-step__arrow{
      top: -10px;
   }
   .v-step[data-popper-placement^="left"] > .v-step__arrow{
      right: 0;
   }
   /* ORDER BUTTONS */
   .v-step__buttons{
      display: flex;
      justify-content: center;
      .v-step__button-previous{
         order:0;
      }
      .v-step__button-skip{
         order:1;
      }
      .v-step__button-next{
         order:2;
      }
   }

}