.p-sidebar-content{
  a,
  button{
    display:block;
  }
  a{
    color:$siemens-title-color;
    text-decoration:none;
    &:hover,
    &:active,
    &:focus{
      color:$siemens-bold-blue;
      text-decoration:underline;
    }
  }
  ul{
    list-style-type:none;
    margin:0.5rem 0;
    padding:0;
    li{
      padding:0.5rem 0;
    }
  }
  p.p-component,
  ul{
    /*margin-left:1.5rem;*/
  }
  .p-button {
    padding: 0.5rem 1.5rem;
  }
}