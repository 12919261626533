@import "primevue-sass-theme-main/themes/siemens/dark/theme";
@import "primevue/resources/primevue.min.css";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";
@import "vue3-tour/dist/vue3-tour.css";
@import 'vue-plyr/dist/vue-plyr.css';
@import 'floating-vue/dist/style.css';

:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #000000;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 20px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.popper {
  z-index: 999 !important;
}