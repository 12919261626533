.welcome.p-card{
  background:$siemens-title-color;
  color:$siemens-deep-blue;
  //margin:2rem 0;
  h1,h2,h3,h4,h5,h6,p,ul,ol{
    color:$siemens-deep-blue;
  }
  h1,h2,h3,h4,h5,h6{
    margin:0.5em 0;
  }
  a{
    color:$siemens-petrol;
    text-decoration:underline;
    &:hover,
    &:active,
    &:focus{
      text-decoration:none;
      color:$siemens-bold-blue
    }
  }
  .p-button{
    margin:0.5em 0;
  }
}
.share .m-5{
  margin:1rem !important;
  @media screen and (min-width:$breakpoint-xsmall){
    margin:2rem !important;
  }
}
/*
.share:has(.welcome){
  display:flex;
  flex-direction:column;
  align-items:center;
}

.share.welcome{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
}*/