.site-main--controls{
  .p-button{
    background:transparent;
    border-top:1px solid transparent;
    border-left:1px solid transparent;
    border-right:1px solid transparent;
    border-bottom:1px solid $siemens-gray;
    color:$siemens-gray;
    width:50%;
    height:100%;
    display:flex;
    justify-content:center;
    &:hover,
    &:active,
    &:focus{
      color:$siemens-title-color;
      border-bottom:1px solid $siemens-title-color;
      border-top:1px solid transparent;
      border-left:1px solid transparent;
      border-right:1px solid transparent;
      background:transparent;
      outline:0;
      box-shadow:none;
    }
    &.highlighted-button{
      border-bottom:1px solid $siemens-bold-blue;
      color:$siemens-bold-blue;
    }
  }
}