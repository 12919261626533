.p-dialog-mask.p-component-overlay{
  .plyr{
    max-width:75vw;
    max-height:75vh;
    aspect-ratio: 16 / 9;
    margin:0 auto;
  }
  &:before{
    position:absolute;
    content:'';
    top:0;
    left:0;
    bottom:0;
    right:0;
    background:rgba($siemens-deep-blue,0.9);
  }
}