html,body{
  box-sizing: border-box;
  padding: 0;
  margin: 0;

}
html,body{
  height:100%;
  width:100%;
  height:100vh;
  width:100vw;
  height: 100dvh;
  width: 100dvw;
  font-size: 16px;
}
/*SCALING FOR HIGH DPI Monitors with scaling
@media (-webkit-device-pixel-ratio: 1.5){
  body{
    zoom:0.8;
  }
}*/
.site,
#app{
  height:100%;
  height:100vh;
  height: 100dvh; /* Do this! */
}
body{
  font-family:siemensRegular, sans-serif;
  font-size: 16px;
  background: $siemens-deep-blue;
  /*background: oklch(70% 0.197 138.71);*/
}
.site,
#app{
  overflow:hidden;
  display: grid;
  grid-template-rows: 60px 1fr;
  grid-template-columns: 100vw;
  @media screen and (min-width:1025px){
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 60px 160px 1fr;
  }
}
.site-header{
  grid-row-start: 1;
  @media screen and (min-width:$breakpoint-large + 1) {
    grid-column: 1 / span 2;
  }
}
.site-main{
  grid-row-start: 2;
  grid-row-end: 3;
  display: grid;
  grid-template-rows: 40px 1fr;
  grid-template-columns: 100vw;
  /*@media screen and (min-width: $breakpoint-large){
    grid-row-end: 3;
  }*/
  @media screen and (min-width:$breakpoint-large + 1) {
    grid-row-start: 2;
    grid-row-end: span 2;
    grid-column: 1 / span 2;
    display: grid;
    grid-template-rows: 160px 1fr;
    grid-template-columns: 1fr 1fr;
  }
}
.site-main--controls{
  grid-row-start: 1;
  grid-row-end: 2;
  /*grid-column:1 / span 2;*/
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}
.site-footer{
  /*grid-row-start: 3;*/
  display:none;
  /*@media screen and (min-width:$breakpoint-large + 1) {
    display:block;
    grid-row-start: 4;
    grid-column: 1 / span 2;
  }*/
}

.site-main--left{
  grid-row-start: 2;
  grid-column:1;
  grid-column-end: 2;
  display: grid;
  grid-template-rows: 55px 1fr;
  padding: 0;

  @media screen and (min-width:$breakpoint-large + 1) {
    grid-row-start: 1;
    grid-row-end: span 2;
    grid-column:1;
    grid-column-end: 2;
    display: grid;
    grid-template-rows: 120px 1fr;
    padding: 20px 5px 20px 20px;
  }
  /*MOBILE INFO PANE
  @media screen and (orientation:portrait) and (min-width: 320px) and (max-width: $breakpoint-large + 1){
    padding: 40px 0 10px 0;
  }*/
  /* LAPTOPS with windows scaling 125% -150% */
  @media screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width:$breakpoint-large) and (max-width: $breakpoint-superlarge +1){
    padding: 10px 5px 15px 10px;
  }
}
.site-main-mobile .site-main--left{
  max-height: calc(100vh - 160px);
  overflow:hidden;
}
.site-main--right{
  grid-row-start: 2;
  grid-row-end: 3;
  display: grid;
  grid-template-rows: 75px 1fr;
  padding: 20px 10px 10px 10px;
  @media screen and (min-width:$breakpoint-large + 1) {
    grid-row-start: 1;
    grid-row-end: span 2;
    grid-column:2;
    display: grid;
    grid-template-rows: 120px 1fr;
    padding: 20px 10px 10px 10px;
  }
  @media screen and (min-width:$breakpoint-xlarge) {
    padding: 20px 10px 20px 10px;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width:$breakpoint-large) and (max-width: $breakpoint-superlarge +1){
    padding: 10px 10px 15px 10px;
  }
}
.toolbar{
  grid-row-start: 1;
}
.content--left{
  padding: 10px 5px 25px 20px;
  @media screen and (min-width:$breakpoint-large + 1) {
    padding: 60px 0 0 0;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width:$breakpoint-large) and (max-width: $breakpoint-superlarge +1){
    padding: 20px 5px 0 0;
  }
}
.site-main-mobile .content--left{
  max-height: calc(100vh - 155px);
  overflow-y: auto;
}
.content--right{
  overflow-y: auto;
  padding-top: 0;
  padding-bottom:10px;
  @media screen and (min-width:$breakpoint-xsmall) {
    padding-top: 10px;
  }
  @media screen and (min-width:$breakpoint-large + 1) {
    padding-top: 20px;
    padding-bottom:0;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width:$breakpoint-large) and (max-width: $breakpoint-superlarge +1){
    padding-top: 10px;
    padding-bottom:0;
  }
  > div{
    height:100% !important;
    > div{
      height:100% !important;
    }
  }
}
.content--right,
.content--left {
  --scrollbar-foreground: var(--siemens-petrol);
  --scrollbar-background: var(--bg-blue);
  /* Foreground, Background */
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}
.content--right::-webkit-scrollbar,
.content--left::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}
.content--right::-webkit-scrollbar-thumb,
.content--left::-webkit-scrollbar-thumb { /* Foreground */
  background: var(--scrollbar-foreground);
  border-radius: 5px;
}
.content--right::-webkit-scrollbar-track,
.content--left::-webkit-scrollbar-track { /* Background */
  background: var(--scrollbar-background);
  border-radius: 5px;
}
/*
@media screen and (min-width: $breakpoint-medium) and (max-width:$breakpoint-xlarge) and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height:100vw;
    top:100%;
    left:0;
    position: absolute;
    overflow:hidden;
  }
}*/
/*
@media screen and (orientation:portrait) and (max-width: $breakpoint-large){
  .site-main{
    max-width:100vw;
    overflow-y:scroll;
  }
  .site-main--left,
  .site-main--right{
    width:100vw;
    .content--left,
    .content--right{
      padding:10px;
    }
  }
}
*/
