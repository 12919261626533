/*
1. General styling
2. Intro/welcome
3.fixing windows scaling 125%-150%
 */
/*
1. General styling

 */
p,ul,ol{
  /*color: $siemens-gray;*/
  color:var(--gray-100);
  line-height:1.8;
  font-family:siemensRegular, sans-serif;
  font-weight:normal;
}
a{
  color:$siemens-petrol;
  text-decoration:underline;
  font-family:siemensRegular, sans-serif;
  font-weight:normal;
  transition:color 0.5s ease-in-out;
  &:hover,
  &:active,
  &:focus{
    color:$siemens-bold-blue;
    text-decoration:none;
    font-family:siemensRegular, sans-serif;
    font-weight:normal;
    transition:color 0.5s ease-in-out;
  }
}
h1,h2,h3,h4,h5,h6{
  color: $siemens-title-color;
  margin: 1.25em 0;
  font-family:siemensBold, sans-serif;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child{
  margin-top: 0;
}
h1{
  font-size: 1.5rem;
  /*font-size: clamp(1.5rem, calc(1.5rem * 1vw), 1.75rem);*/
  @media screen and (min-width:$breakpoint-large + 1){
    font-size: 1.75rem;
  }
}
h2{
  font-size: 1.25rem;
  @media screen and (min-width:$breakpoint-large + 1) {
    font-size: 1.5rem;
  }
}
h3{
  font-size: 1.15rem;
  @media screen and (min-width:$breakpoint-large + 1) {
    font-size: 1.25rem;
  }
}
/*
2. Intro/welcome
*/
.fancy-welcome-subtitle{
  p{
    font-size:1.25rem;
    line-height: 1.5;
  }
}

/*
3.fixing windows scaling 125%-150%
*/